<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{'open-advanced-search-modal': $store.state.utils.isAdvancedSearchOpen}">
    <div class="">
      <div class="advanced-search-modal">
        <div class="close-modal-button d-none d-md-flex justify-content-end" v-on:click="toggleAdvancedSearch">
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.7041 1.4082L42.5596 42.2637" stroke="black" stroke-width="2.15306" stroke-linecap="round"/>
            <path d="M42.5605 1.4082L1.70501 42.2637" stroke="black" stroke-width="2.15306" stroke-linecap="round"/>
          </svg>

        </div>
        <div class="row advanced-search-title">{{ $t('advanced-search') }}</div>
        <!--        <div class="advanced-search-map"></div>-->
        <div class="advanced-search-filters-container row" v-if="propertySettings">
          <div class="col-md-4 filter-element">
            <label> {{ $t('city') }}</label>
            <Dropdown :label="$t('please-select')" v-model="propertyLocation" :selectedProp="propertyLocation"
                      :options="citiesOptions" v-if="citiesOptions"/>
          </div>
          <div class="col-md-4 filter-element">
            <label>{{ $t('neighbourhood') }}</label>
            <Dropdown :label="$t('please-select')" v-model="filter.district_id" :selectedProp="filter.district_id"
                      :options="districts" v-if="districts"/>
          </div>
          <div class="col-md-4 filter-element">
            <div class="label-item"> {{ $t('label-property-type') }}</div>
            <Dropdown :label="$t('please-select')" v-model="filter.type" :selectedProp="filter.type"
                      :options="propertySettings.type"/>
          </div>
          <div class="col-md-4 filter-element">
            <div class="label-item"> {{ $t('label-property-construction') }}</div>
            <Dropdown :label="$t('please-select')" v-model="filter.construction" :selectedProp="filter.construction"
                      :options="propertySettings.construction"/>
          </div>
          <div class="col-md-4 filter-element">
            <div class="label-item"> {{ $t('label-property-heating') }}</div>
            <Dropdown :label="$t('please-select')" v-model="filter.heating" :selectedProp="filter.heating"
                      :options="propertySettings.heating"/>
          </div>
          <div class="col-md-4 filter-element">
            <div class="label-item"> {{ $t('label-property-furniture') }}</div>
            <Dropdown :label="$t('please-select')" v-model="filter.furniture" :selectedProp="filter.furniture"
                      :options="propertySettings.furniture"/>
          </div>
          <div class="col-md-4 filter-element">
            <label> {{ $t('floor')}}</label>
            <div class="row">
              <div class="col-6">
                <InputField :label="$t('floor-from')" v-model="filter.floorFrom" :content="filter.floorFrom"/>
              </div>
              <div class="col-6">
                <InputField :label="$t('floor-to')" v-model="filter.floorTo" :content="filter.floorTo"/>
              </div>
            </div>
          </div>
          <div class="col-md-4 filter-element">
            <label>{{ $t('total-number-of-floors') }}</label>
            <Dropdown :label="$t('please-select')" :options="{1:1, 2:2}"/>
          </div>
          <div class="col-md-4 filter-element">
            <label>{{ $t('price') }}</label>
            <div class="row">
              <div class="col-6">
                <InputField :label="$t('price-from')" v-model="filter.priceFrom" :content="filter.priceFrom"/>
              </div>
              <div class="col-6">
                <InputField :label="$t('price-to')" v-model="filter.priceTo" :content="filter.priceTo"/>
              </div>
            </div>
          </div>

        </div>
        <div class="advanced-search-select row d-flex justify-content-center">
          <div class="d-flex justify-content-center filter-element my-5">
            <div class="select-item d-flex me-4" v-if="false">
              <Checkbox/>
              <div class="ms-1">{{ $t('ads-with-photos') }} </div>
            </div>
            <div class="select-item d-flex me-4">
              <Checkbox v-model="filter.premium"/>
              <div class="ms-1">{{ $t('premium-ads') }} </div>
            </div>
            <div class="select-item d-flex">
              <Checkbox v-model="filter.petFriendly"/>
              <div class="ms-1">{{ $t('pet-friendly') }} </div>
            </div>
          </div>
          <div class="col-md-4 mt-2">
            <Button
                :size="'medium'" :theme="'solid-orange'" :text="$t('search')"
                @click="searchProperty()"
            />
              <div class="col-md-4 my-2 d-md-none " v-on:click="toggleAdvancedSearch">
                  <Button :size="'medium'" :theme="'solid-grey'" :text="$t('show-results')"/>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

